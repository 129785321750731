import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { 
  MdPlayCircleFilled, MdPauseCircleFilled,
  MdReplay30, MdForward30,
  MdInfoOutline, MdFormatAlignJustify,
} from 'react-icons/md';

import Download from './icons/Download';

import AudioProgressBar from './AudioProgressBar';
import PlayBackRate from './PlayBackRate';
import PodcastSubscribeLink from './PodcastSubscribeLink';

import { AppContext } from '../AppContext';
import { Episode, Podcast } from '../types';
import AudioPlayer from '../utils/AudioPlayer';

import './PodcastWidgetPlayer.scss';

type Props = {
  podcast: Podcast;
  episode?: Episode;
  requestedTimestamp?: number;
  showDescription?: boolean;
  showEpisodeList?: boolean;
  showDescriptionToggle?: boolean;
  showEpisodeListToggle?: boolean;
  onToggleDescription?: () => void;
  onToggleEpisodeList?: () => void;
}

const PodcastWidgetPlayer = ({ 
  podcast, episode, 
  requestedTimestamp,
  showDescription, showEpisodeList,
  showDescriptionToggle = true,
  showEpisodeListToggle = true,
  onToggleDescription,
  onToggleEpisodeList,
}: Props) => {

  const context = useContext(AppContext);

  // State

  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ currentTime, setCurrentTime ] = useState(0);
  const [ playBackRate, setPlayBackRate ] = useState(1);

  // Refs

  const audio = useRef<AudioPlayer | undefined>(undefined);

  // Effects

  useEffect(function episodeChanged() {
    // Reset howl audio
    if (audio.current) {
      audio.current.destroy();
      setIsPlaying(false);
      setCurrentTime(0);
      setPlayBackRate(1);
    }
    audio.current = undefined;
    
  }, [ episode ]);

  // Actions

  const onPlay = useCallback(() => {
    setIsPlaying(true);
    if (!showDescription) {
      onToggleDescription && onToggleDescription();
    } 
  }, [ showDescription, setIsPlaying, onToggleDescription ]);
  const onPause = useCallback(() => setIsPlaying(false), [ setIsPlaying ]);
  const onTimeUpdate = useCallback(() => audio.current && setCurrentTime(audio.current.currentTime), [ audio, setCurrentTime ]);
  const onPlayRateChange = useCallback(() => audio.current && setPlayBackRate(audio.current.playbackRate), [ audio, setPlayBackRate ]);
  
  const handleBack = () => {
    if (audio.current) {
      const currentTime = audio.current.audio.currentTime;
      audio.current.audio.currentTime = Math.max(0, currentTime - 30);
    }
  };
  
  const handleForward = () => {
    if (audio.current) {
      const currentTime = audio.current.audio.currentTime;
      audio.current.audio.currentTime = Math.min(audio.current.audio.duration, currentTime + 30);
    }
  };

  const initAudioPlayer = useCallback(() => {
    if (episode?.file.url && !audio.current) {
      audio.current = new AudioPlayer(episode?.file.url);
      audio.current.addEventListener('play', onPlay);
      audio.current.addEventListener('pause', onPause);
      audio.current.addEventListener('timeupdate', onTimeUpdate);
      audio.current.addEventListener('ratechange', onPlayRateChange);
    }
  }, [ episode, audio, onPlay, onPause, onTimeUpdate, onPlayRateChange ]);
  
  const handlePlay = useCallback(() => {
    initAudioPlayer();
    if (audio.current) {
      audio.current.play();
    }
  }, [ audio, initAudioPlayer ]);
    
  const handlePause = useCallback(() => {
    if (audio.current) {
      audio.current.pause();
    }
  }, [ audio ]);

  const handlePlayBackRateChange = useCallback((rate) => {
    initAudioPlayer();
    if (audio.current) {
      audio.current.setPlayBackRate(rate);
    }
  }, [ audio, initAudioPlayer ]);

  const handleSeekAudio = useCallback((time) => {
    initAudioPlayer();
    if (audio.current) {
      audio.current.setCurrentTime(time);
    }
  }, [ audio, initAudioPlayer ]);

  const previousRequestTimestamp = useRef<number | undefined>();
  useEffect(function requestedTimestampChanged() {
    if (requestedTimestamp !== undefined && (!previousRequestTimestamp.current || previousRequestTimestamp.current !== requestedTimestamp)) {
      handleSeekAudio(requestedTimestamp);
      handlePlay();
      previousRequestTimestamp.current = requestedTimestamp;
    }
  }, [ requestedTimestamp, handleSeekAudio, handlePlay ]);

  const handleEpisodeDownload = useCallback(
    () => episode && window.open(episode?.file.url),
    [ episode ],
  );

  return (
    <div className='lc-podcast-widget-player' style={{ background: context.primary, color: context.secondary }}>
      <div className='lc-podcast-image-container'>
        <img src={episode?.imageUrl || podcast.imageUrl} className='lc-podcast-image' alt='Podcast Cover' />
        
        <div className='lc-podcast-subscribe-container'>
          {podcast.services.map(({ podcast_service_id, url }) => (
            <PodcastSubscribeLink key={podcast_service_id} service={podcast_service_id} url={url} />
          ))}
          <PodcastSubscribeLink url={podcast.feedLink} />
        </div>
      </div>

      <div className='lc-podcast-player'>
        <div className='lc-podcast-player-buttons main'>
          <MdReplay30 className='lc-podcast-seek' onClick={handleBack} />
          {isPlaying ? 
            <MdPauseCircleFilled className='lc-podcast-play-pause' onClick={handlePause} /> :
            <MdPlayCircleFilled className='lc-podcast-play-pause' onClick={handlePlay} />
          }
          <PlayBackRate className='hide-sm-up' rate={playBackRate} values={[0.5, 1, 1.2, 1.5, 2 ]} onChange={handlePlayBackRateChange} />
          <MdForward30 className='lc-podcast-seek' onClick={handleForward} />
        </div>

        <div className='lc-podcast-player-buttons hide-xs'>
          <PlayBackRate rate={playBackRate} values={[0.5, 1, 1.2, 1.5, 2 ]} onChange={handlePlayBackRateChange} />
        </div>

        <div className='lc-podcast-audio'>
          <div className='lc-podcast-audio-top'></div>

          <AudioProgressBar 
            currentTime={currentTime} duration={episode?.file.duration} 
            onSeek={handleSeekAudio}
          />

          <div className='lc-podcast-audio-bottom'>
            <div className='lc-podcast-episode-title'>{episode?.title}</div>
            <div className='lc-podcast-title'>{podcast?.title}</div>
          </div>
        </div>

        <a href='https://www.lilicast.com' target='_blank' rel='noopener noreferrer' className='lc-powered-lilicast'>
          Powered by LiLiCAST
        </a>
      </div>

      <div className='lc-podcast-actions'>
        {showDescriptionToggle && (
          <MdInfoOutline 
            className={`lc-podcast-episode-description ${showDescription ? 'active' : ''}`}
            onClick={onToggleDescription} 
          />
        )}
        {showEpisodeListToggle && (
          <MdFormatAlignJustify 
            className={`lc-podcast-episode-list ${showEpisodeList ? 'active' : ''}`}
            onClick={onToggleEpisodeList} 
          />
        )}
        <Download className='lc-podcast-episode-download' onClick={handleEpisodeDownload} />
      </div>
    </div>
  );
};

export default PodcastWidgetPlayer;