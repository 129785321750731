import React, { memo } from 'react';

import { FaSpotify, FaApple, FaGoogle, FaRss } from 'react-icons/fa';

import './PodcastSubscribeLink.scss';

type Props = {
  service?: string;
  url: string;
}

const PodcastSubscribeLink = memo(({ service, url }: Props) => {
  const props = {
    className: 'lc-podcast-subscribe-link',
    onClick: () => window.open(url, '_subscribe'),
  };
  switch (service) {
    case 'spotify': return <FaSpotify {...props} />;
    case 'apple': return <FaApple {...props} />;
    case 'google': return <FaGoogle {...props} />;
    case 'rss':
    default: 
      return <FaRss style={{fontSize: 'smaller' }} {...props} />;
  }
});


export default PodcastSubscribeLink;