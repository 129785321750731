import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { Episode } from '../types';
import Linkify from './Linkify';

import './PodcastWidgetDescription.scss';

type Props = {
  episode: Episode;
  height: number;
  show?: boolean;
  onClickTimestamp?: (timestamp: number) => void;
}

const PodcastWidgetDescription = ({ episode, height, show = false, onClickTimestamp }: Props) => {

  const { primary, secondary } = useContext(AppContext);

  return (
    <div className={`lc-podcast-widget-episode-description ${show ? 'show' : 'hidden'}`} style={{ height, background: primary, color: secondary }}>
      <Linkify 
        className='lc-podcast-episode-description' text={episode.description} 
        onClickTimestamp={onClickTimestamp}
      />
    </div>
  );
};


export default PodcastWidgetDescription;