import React, { useContext, useRef } from 'react';
import { AppContext } from '../AppContext';

import './ProgressBar.scss';

interface Props {
  current: number;
  total?: number;
  onClick?: (value: number, percent: number) => void;
};

const ProgressBar = ({ current, total, onClick }: Props) => {

  const { primaryAlt, secondary } = useContext(AppContext);

  const element = useRef<HTMLDivElement>(null);

  const handleClick = (evt: React.MouseEvent) => {
    if (!element.current) return;

    const { x, width } = element.current.getBoundingClientRect();
    const clickX = evt.clientX;

    const percent = (clickX - x) / width;
    const value = percent * (total || 0);

    onClick && onClick(value, percent);
  };

  const progress = total ? current / total * 100 : 0;
  return (
    <div className='lc-progress-bar'>
      <div 
        ref={element}
        className='lc-full-bar' 
        style={{ backgroundColor: primaryAlt }}
        onClick={handleClick} 
      >
        <div className='lc-current-bar' style={{ width: `${progress}%`, background: secondary }} /> 
      </div> 
    </div>
  );
};

export default ProgressBar;