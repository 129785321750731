import React, { useContext } from 'react';
import { AppContext } from '../AppContext';

import './PlayBackRate.scss';

type Props = {
  className?: string;
  rate: number;
  values: number[];
  onChange: (rate: number) => void;
};

const PlayBackRate = ({ className = '', rate, values, onChange }: Props) => {

  const { primary, secondary } = useContext(AppContext);

  const handleClick = () => {
    const idx = values.indexOf(rate);
    if (idx < 0 || idx >= values.length - 1) return onChange(values[0]);

    return onChange(values[idx + 1]);
  };

  return (
    <button className={`lc-playback-rate ${className}`} onClick={handleClick} style={{ backgroundColor: primary, color: secondary, borderColor: secondary }}>
      {rate}x
    </button>
  );
};

export default PlayBackRate;