class AudioPlayer {

  url: string;

  audio: HTMLAudioElement;

  protected listeners: Record<string, (() => void)[]>;

  constructor(url: string) {
    this.url = url;
    this.audio = new Audio(url);
    this.listeners = {};
  }

  get currentTime() {
    return this.audio.currentTime;
  }

  get playbackRate() {
    return this.audio.playbackRate;
  }

  setCurrentTime(time: number) {
    if (this.audio) {
      this.audio.currentTime = time;
    }
  }

  setPlayBackRate(rate: number) {
    if (this.audio) {
      this.audio.playbackRate = rate;
    }
  }

  play() {
    if (this.audio.paused) {
      this.audio.play();
    }
  }

  pause() {
    if (!this.audio.paused) {
      this.audio.pause();
    }
  }

  addEventListener(event: string, listener: () => void) {
    if (this.audio) {
      this.audio.addEventListener(event, listener);
      let listeners = this.listeners[event];
      if (!listeners) {
        listeners = [];
        this.listeners[event] = listeners;
      }
      listeners.push(listener);
    }
  }

  destroy() {
    if (this.audio) {
      this.pause();
      
      for (const event of Object.keys(this.listeners)) {
        const listeners = this.listeners[event];
        console.log(`Removing ${listeners.length} ${event} listeners`);
        listeners.forEach(listener => this.audio.removeEventListener(event, listener));
      }
      this.listeners = {};
    }
  }

}

export default AudioPlayer;
