import React, { memo, useCallback } from 'react';

type Props = {
  text: string;
  className?: string;
  onClickTimestamp?: (timestamp: number) => void;
}

enum ItemType {
  Text = 'text',
  Timestamp = 'timestamp',
  Link = 'link',
  Hashtag = 'hashtag',
}

interface Item {
  type: ItemType;
  text: string;
  index: number;
  timestamp?: number;
};

// const reTime = /\s(?:(?:([01]?\d):)?([0-5]?\d))?:([0-5]?\d)\s/g
const reUrl = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/i;
const reTime = /(\d\d):([0-5]\d):([0-5]\d)/;

const parseText = (text: string, index: number = 0, res: Item[] = []): Item[] => {
  // console.log('parseText', text, index);

  if (!text || text.length === 0) return res;

  const nextUrl = text.match(reUrl);
  const nextTs = text.match(reTime);

  // console.log('nextUrl', nextUrl);
  // console.log('nextTs', nextTs);

  if (!nextUrl && !nextTs) {
    // console.log('Using full text');
    res.push({
      type: ItemType.Text,
      index, text, 
    });
    return res;
  }

  const foundUrl = nextUrl && (nextUrl.index !== undefined);
  const foundTs = nextTs && (nextTs.index !== undefined);
  const urlIdx = nextUrl?.index || 0;
  const tsIdx = nextTs?.index || 0;

  // console.log('foundUrl / foundTs', foundUrl, foundTs);
  // console.log('urlIdx / tsIdx', urlIdx, tsIdx);

  if (foundTs && (!foundUrl || (tsIdx < urlIdx))) {
    // console.log('Using TS', nextTs);
    const [ ts, hours, minutes, seconds ] = nextTs || [];
    res.push({
      type: ItemType.Text,
      text: text.substr(0, tsIdx),
      index,
    });
    res.push({
      type: ItemType.Timestamp,
      text: ts,
      timestamp: parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10),
      index: index + tsIdx,
    });

    const nextIndex = tsIdx + ts.length;
    return parseText(text.substr(nextIndex), nextIndex, res);
    
  } else {
    // console.log('Using URL', nextUrl);
    const [ link ] = nextUrl || [];
    res.push({
      type: ItemType.Text,
      text: text.substr(0, urlIdx),
      index,
    });
    res.push({
      type: ItemType.Link,
      text: link,
      index: index + urlIdx,
    });
    
    const nextIndex = urlIdx + link.length;
    return parseText(text.substr(nextIndex), nextIndex, res);
  }

};

const Linkify = memo(({ text, className, onClickTimestamp }: Props) => {

  const handleClickTimestamp = useCallback(
    (timestamp?: number) => (onClickTimestamp && timestamp !== undefined) && onClickTimestamp(timestamp),
    [ onClickTimestamp ],
  );

  const cleanText = text
    .replace(/&nbsp;/g, ' ')
    .replace(/<br ?\/?>/gi, '\n');
  const parsed = parseText(cleanText);

  const renderItem = useCallback((item: Item, idx: number) => {
    switch(item.type) {
      case ItemType.Timestamp:
        return <span key={`item-${idx}`} className='lc-timestamp' onClick={() => handleClickTimestamp(item.timestamp)}>{item.text}</span>;
      case ItemType.Link:
        return <a key={`item-${idx}`} href={item.text} target='_blank' rel='noreferrer noopener' className='lc-link'>{item.text}</a>;
      case ItemType.Text:
      default: 
        return item.text; 
    }
  }, [ handleClickTimestamp ]);

  return (
    <div className={className}>
      {parsed.map(renderItem)}
    </div>
  );
});


export default Linkify;