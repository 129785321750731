import React from 'react';
import PropTypes from 'prop-types';

interface Props {
  className?: string;
  onClick?: () => void;
}

const Download = ({ className, onClick }: Props) => (
  <svg 
    width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
    className={className} onClick={onClick}
  >
    <path d="M18.3333 12.9167V17.9167C18.3333 18.1667 18.1667 18.3333 17.9167 18.3333H2.08334C1.83334 18.3333 1.66667 18.1667 1.66667 17.9167V12.9167H0.833341V17.9167C0.833341 18.625 1.37501 19.1667 2.08334 19.1667H17.9167C18.625 19.1667 19.1667 18.625 19.1667 17.9167V12.9167H18.3333Z" fill="currentColor"/>
    <path d="M14.4584 10.7083L13.875 10.125L10.4167 13.5833V0.833328H9.58335V13.5833L6.12502 10.125L5.54169 10.7083L10 15.1667L14.4584 10.7083Z" fill="currentColor"/>
  </svg>
);

Download.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Download;
