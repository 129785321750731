import React from 'react';
import tinycolor from 'tinycolor2';

export const getAltColor = (color: string) => {
  const c = tinycolor(color);
  return c.isLight()
    ? c.darken(20).toRgbString()
    : c.lighten(20).toRgbString();
}

export const defaultContext = {
  primary: '#3F424A',
  primaryAlt: getAltColor('#3F424A'),
  secondary: '#FFF',
};

export const AppContext = React.createContext(defaultContext);
