import React from 'react';
import { formatDuration } from '../utils/duration';
import ProgressBar from './ProgressBar';

import './AudioProgressBar.scss';

interface Props {
  currentTime: number;
  duration?: number;
  onSeek?: (duration: number) => void;
};

const AudioProgressBar = ({ currentTime, duration, onSeek }: Props) => {
  const remaining = duration && duration - currentTime;

  return (
    <div className='lc-podcast-audio-progress'>
      <span className='lc-podcast-current'>{formatDuration(currentTime)}</span>
      <ProgressBar 
        current={currentTime} total={duration} 
        onClick={onSeek}
      />
      <span className='lc-podcast-remaining'>{remaining ? formatDuration(-remaining) : '--:--'}</span>
    </div>
  );
};

export default AudioProgressBar;
