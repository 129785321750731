import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import { MdExplicit } from 'react-icons/md';

import Download from './icons/Download';

import { AppContext } from '../AppContext';
import { Episode, Podcast } from '../types';
import { formatDuration } from '../utils/duration';

import './PodcastWidgetEpisodes.scss';

interface PodcastWidgetEpisodeProps {
  podcast: Podcast;
  episode: Episode;
  onSelect: () => void;
};

const PodcastWidgetEpisode = ({ podcast, episode, onSelect } : PodcastWidgetEpisodeProps) => {

  const { primary, secondary } = useContext(AppContext);

  const handleDownloadEpisode = useCallback(
    () => {
      console.log('handleDownloadEpisode', episode); 
      window.open(episode.file.url, '_blank');
    },
    [ episode ],
  );

  return (
    <div className='lc-podcast-widget-episode' style={{ background: primary, color: secondary }}>
      <span className='lc-podcast-episode-image' onClick={onSelect}>
        <img src={episode.imageUrl || podcast.imageUrl} alt='Episode' />
      </span>
      <span className='lc-podcast-episode-title' onClick={onSelect}>
        <span className='lc-podcast-episode-title-content'>
          {episode.explicit && (
            <MdExplicit className='lc-podcast-episode-explicit' />
          )}
          {episode.title}
        </span>
      </span>
      <span className='lc-podcast-episode-duration'>
        {formatDuration(episode.file.duration)}
      </span>
      <span className='lc-podcast-episode-date'>
        {moment(episode.publication_date).format('L')}
        {/* {moment(episode.publication_date).format('L LT')} */}
      </span>
      <span className='lc-podcast-episode-download' onClick={handleDownloadEpisode}>
        <Download />
      </span>
    </div>
  );
};

interface Props {
  podcast: Podcast;
  episodes: Episode[];
  height: number;
  onSelectEpisode: (episode: Episode) => void;
}

const PodcastWidgetEpisodes = ({ podcast, episodes = [], height, onSelectEpisode } : Props) => {
  const { primary } = useContext(AppContext);

  return (
    <div className='lc-podcast-widget-episodes' style={{ height, background: primary }}>
      <div className='lc-podcast-widget-episodes-container'>
        {episodes.map((episode) => (
          <PodcastWidgetEpisode 
            key={episode.id}
            podcast={podcast}
            episode={episode}
            onSelect={() => onSelectEpisode(episode)}
          />
        ))}
      </div>
    </div>
  );
};

export default PodcastWidgetEpisodes;