import React, { useCallback, useEffect, useState } from 'react';

import { AppContext, defaultContext, getAltColor } from './AppContext';
import Loading from './components/Loading';
import PodcastWidgetPlayer from './components/PodcastWidgetPlayer';
import PodcastWidgetEpisodes from './components/PodcastWidgetEpisodes';
import PodcastWidgetDescription from './components/PodcastWidgetDescription';

import config from './config';
import { Episode, Podcast } from './types';

import './App.scss';

const EPISODE_HEIGHT = 50;
const MIN_HEIGHT = 3 * EPISODE_HEIGHT;
const MAX_HEIGHT = 5 * EPISODE_HEIGHT;

const App = () => {
  
  const urlParams = new URLSearchParams(window.location.search);

  const primary = urlParams.get('primary');
  const secondary = urlParams.get('secondary');

  const hasEpisodeList = !urlParams.has('episodeId');
  const defaultEpisodeDescription = urlParams.has('description') && ['true', '1'].includes(urlParams.get('description')?.toLowerCase() || '');
  const showDescriptionToggle = defaultEpisodeDescription || hasEpisodeList;

  const context = { ...defaultContext };
  if (primary) context.primary = `#${primary}`;
  if (secondary) context.secondary = `#${secondary}`;

  context.primaryAlt = getAltColor(context.primary);

  // State 
  const [ podcast, setPodcast ] = useState<Podcast | undefined>(undefined);
  const [ episodes, setEpisodes ] = useState<Episode[] | undefined>(undefined);
  const [ episode, setEpisode ] = useState<Episode | undefined>(undefined);

  const [ showDescription, setShowDescription ] = useState<boolean>(defaultEpisodeDescription);
  const [ showEpisodeList, setShowEpisodeList ] = useState<boolean>(true);
  
  const [ requestedTimestamp, setRequestedTimestamp ] = useState<number | undefined>();

  // Effects

  const loadPodcast = useCallback(
    async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const podcastId = urlParams.get('podcastId');
      const episodeId = urlParams.get('episodeId');
      const episodeNr = urlParams.get('episodes');
      
      const fetchUrl = episodeId ? 
        `${config.lilicast.server}/api/public/podcast/${podcastId}/episode/${episodeId}/widget.json` :
        `${config.lilicast.server}/api/public/podcast/${podcastId}/widget.json?${episodeNr ? `episodes=${episodeNr}` : ''}`;

      const response = await fetch(fetchUrl);
      const { podcast, episodes, episode } = await response.json();
      setPodcast(podcast);
      setEpisodes(episodes);
      setEpisode(episode || (episodes && episodes[0]));
    },
    [ setPodcast, setEpisodes, setEpisode ],
  );

  useEffect(function componentDidMount() {
    loadPodcast();
  }, [ loadPodcast ]);

  // Actions
  const handleRequestTimestamp = useCallback((timestamp) => {
    setRequestedTimestamp(timestamp);
  }, [ setRequestedTimestamp ]);

  const handleSelectEpisode = useCallback((episode) => {
    setRequestedTimestamp(undefined);
    setEpisode(episode);
  }, [ setEpisode ]);

  const handleToggleEpisodeList = useCallback(() => {
    setShowEpisodeList(hasEpisodeList && !showEpisodeList);
    setShowDescription(showEpisodeList);
  }, [ showEpisodeList, hasEpisodeList, setShowEpisodeList, setShowDescription ]);

  const handleToggleDescription = useCallback(() => {
    setShowDescription(!showDescription);
    setShowEpisodeList(hasEpisodeList && showDescription);
  }, [ showDescription, hasEpisodeList, setShowEpisodeList, setShowDescription ]);

  const height = Math.max(MIN_HEIGHT, Math.min(MAX_HEIGHT, (episodes?.length || 1) * EPISODE_HEIGHT));

  return (podcast) ? (
    <AppContext.Provider value={context}>
      <div id='podcast-widget' className='lc-podcast-widget' style={{ height: 200 + height }}>
        { episode && (
          <PodcastWidgetPlayer 
            podcast={podcast} 
            episode={episode} 
            requestedTimestamp={requestedTimestamp}
            showDescription={showDescription}
            showDescriptionToggle={showDescriptionToggle}
            showEpisodeList={showEpisodeList}
            showEpisodeListToggle={hasEpisodeList}
            onToggleDescription={handleToggleDescription}
            onToggleEpisodeList={handleToggleEpisodeList}
          />
        )}

        {episode && (
          <PodcastWidgetDescription 
            episode={episode} 
            show={showDescription} 
            height={height} 
            onClickTimestamp={handleRequestTimestamp}
          />
        )}
        
        { episodes && (
          <PodcastWidgetEpisodes 
            podcast={podcast} episodes={episodes} 
            height={height}
            onSelectEpisode={handleSelectEpisode}
          />
        )}
      </div>
    </AppContext.Provider>
  ) : (
    <Loading />
  );
};

export default App;
